import { LuListTree } from 'react-icons/lu'

import { Badge } from '~/components/UI/Badge'

const ProductCodeReference: React.FC<{ data: PublisherProduct }> = ({
  data
}) => {
  const value = data?.product_reference_code

  return value ? (
    <div className="d-flex flex-wrap gap-1">
      <Badge size="small" iconLeft={<LuListTree />} text={value} />
    </div>
  ) : (
    <></>
  )
}

export default ProductCodeReference
