import { bffRetailMedia } from '~/middlewares/axios'

/**
 * List all attributes
 */
export const listAttributesService = (
  params?: AudienceAttributesRequestQuery
) =>
  bffRetailMedia.get<AudienceAttributesResponse>(`/audience/attributes`, {
    params
  })
