import { AlertConfirmation } from '~/components/AlertConfirmation'
import { isAdvertiser, isPublisher } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import ButtonCreateCampaignWithAudience from '../../elements/ButtonCreateCampaignWithAudience'

const HeaderAudienceView: React.FC<AudienceViewHeaderProps> = ({ data }) => {
  const { t } = useAPPTranslation()

  const isApproved = data.status === 'approved'
  const showButtonCreateCampaign = isAdvertiser() && isApproved

  return (
    <>
      <header className="d-flex gap-2 justify-content-between">
        <section>
          <strong>{data.name}</strong>
          <br />
          {data?.description && <span>{data.description}</span>}
        </section>

        <aside>
          {showButtonCreateCampaign && (
            <ButtonCreateCampaignWithAudience id={data.id} />
          )}
        </aside>
      </header>

      {data.status === 'awaiting_approval' && (
        <AlertConfirmation
          title={t('rm:audience.alerts.review.title')}
          description={t(
            `rm:audience.alerts.review_${isPublisher() ? 'publisher' : 'advertiser'}.description`
          )}
          template="warning"
        />
      )}

      <hr />
    </>
  )
}

export default HeaderAudienceView
