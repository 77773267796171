import {
  getIsNetwork,
  getIsWhiteLabel,
  isAdvertiser,
  isPublisher
} from '~/helpers'
import * as allRoutes from '~/routes'

type RoutesKey =
  | AccountTypes
  | 'common'
  | 'commonWhiteLabel'
  | 'groceryWhiteLabel'
  | 'industryWhiteLabel'

export const filterRoutes = ({
  modules,
  accountType = 'grocery'
}: {
  modules: AccessModulesResponse[]
  accountType: AccountTypes | 'common'
}) => {
  const isWhiteLabel = getIsWhiteLabel()
  const isNetwork = getIsNetwork()

  const routesKey: RoutesKey = isWhiteLabel
    ? `${accountType}WhiteLabel`
    : accountType

  const privateRoutes = allRoutes[routesKey]

  const flattenModules: string[] =
    modules?.length &&
    modules.reduce((acc, item) => {
      const childrenIds = item.submodules.map(child => child.access_module_id)

      return [...acc, item.access_module_id, ...childrenIds]
    }, [])

  const cleanRoutes = privateRoutes
    .map(item => {
      const show = !item?.id || flattenModules?.indexOf(item.id) > -1

      const hasChildren = !!item?.children?.length

      const filterByModules = (child: ChildMenuItem) =>
        !child?.id || flattenModules?.indexOf(child.id) > -1

      const filterByWhiteLabel = (child: ChildMenuItem) =>
        isWhiteLabel ? !child?.onlyNewtail : true

      const filterByNetwork = (child: ChildMenuItem) =>
        child?.onlyNetwork ? isNetwork : true

      const filterByPublisher = (child: ChildMenuItem) =>
        isPublisher() ? !child?.onlyAdvertiser : true

      const filterByAdvertiser = (child: ChildMenuItem) =>
        isAdvertiser() ? !child?.onlyPublisher : true

      const filterRoutes = (child: ChildMenuItem) =>
        filterByModules(child) &&
        filterByWhiteLabel(child) &&
        filterByNetwork(child) &&
        filterByPublisher(child) &&
        filterByAdvertiser(child)

      const children = hasChildren ? item.children.filter(filterRoutes) : null

      return show ? { ...item, children } : null
    })
    .filter(item => !!item)

  return cleanRoutes
}
