import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../api'

const baseURL = `${baseAPI}/configuration`

export const getPublisherConfigurationKey = 'getPublisherConfiguration'

export const getPublisherConfigurationService = (publisherId?: string) =>
  axios.get<PublisherConfiguration>(`${baseURL}/v2/publisher/`, {
    params: {
      publisher_id: publisherId
    }
  })
