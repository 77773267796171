import React, {
  useMemo,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useQuery } from '@tanstack/react-query'

import localstorageConstants from '~/helpers/localstorage-manipulation'
import { getAppInfoQueryKey, getAppInfoService } from '~/services/app-info'
import { primary } from '~/styles/themes'

import defaultLogo from '../../assets/img/brands/logo-newtail-vtex-vertical.svg'
import { getValidColor } from '../../helpers/color'
import appInfoDTO from './dto'

const AppInfoContext = createContext<AppInfoProviderData>(
  {} as AppInfoProviderData
)

const AppInfoProvider = ({ children }: React.PropsWithChildren) => {
  const [appInfo, setAppInfo] = useState<AppInfoDTOData | null>(() => {
    const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)

    if (storageValue && !!JSON.parse(storageValue)?.isWhiteLabel) {
      const data = JSON.parse(storageValue)

      const colors = {
        header: getValidColor(data.colors.header),
        primary: getValidColor(data.colors.primary),
        secondary: getValidColor(data.colors.secondary),
        dark: getValidColor(data.colors.dark)
      }

      return { ...data, colors }
    }

    return null
  })

  const hostname = window.location.hostname

  /**
   * Handle request
   */

  const { status, isPending, data } = useQuery({
    queryKey: [getAppInfoQueryKey, hostname],
    queryFn: async () => {
      const res = await getAppInfoService()

      const data = { ...res?.data }

      return res.data ? appInfoDTO(data) : null
    }
  })

  useEffect(() => {
    if (!!data && status === 'success') {
      setAppInfo(data)

      localStorage.setItem(localstorageConstants.APP_INFO, JSON.stringify(data))
    }

    if (data === null && status === 'success') {
      setAppInfo(data)

      localStorage.removeItem(localstorageConstants.APP_INFO)
    }
  }, [data, status])

  const name = useMemo(() => {
    if (!appInfo?.name) {
      if (status === 'error') return ''
      //
      return appInfo?.name
    }

    return appInfo.name
  }, [appInfo, status])

  const logo = useMemo(() => {
    if (!appInfo?.logoWhiteLabel && !appInfo?.logo) {
      if (status === 'error') return ''

      const validLogo = appInfo?.logoWhiteLabel || appInfo?.logo

      return status === 'pending' ? null : validLogo
    }

    return appInfo?.logoWhiteLabel || appInfo?.logo
  }, [appInfo, status])

  const headerLogo = useMemo(() => {
    if (!logo && !appInfo?.headerLogo) {
      if (status === 'error') return ''

      const validLogo = appInfo?.headerLogo || logo

      return status === 'pending' ? null : validLogo
    }

    return appInfo?.headerLogo || logo
  }, [appInfo, logo, status])

  const colors = {
    header: appInfo?.colors?.header || '#ffffff',
    primary: appInfo?.colors?.primary || '#3c3584',
    secondary: appInfo?.colors?.secondary || '#ffb450',
    dark: appInfo?.colors?.dark || primary.dark
  }

  const isWhiteLabel = useMemo(() => !!appInfo, [appInfo])

  const currencyUseCents = useMemo(
    () => appInfo?.currencyCode !== 'COP',
    [appInfo?.currencyCode]
  )

  const lengthCentsCurrency = useMemo(() => {
    if (appInfo?.currencyCode === 'COP') {
      return 0
    }

    return 2
  }, [appInfo?.currencyCode])

  const linkConfig: AppInfoLinkConfig[] = ['adTypes', 'sellerId', 'brands']

  return (
    <AppInfoContext.Provider
      value={{
        linkConfig,
        callbackLogoutUrl: appInfo?.callbackLogoutUrl,
        colors,
        currencyCode: appInfo?.currencyCode,
        currencyUseCents,
        hasRecharge: appInfo?.hasRecharge,
        headerLogo,
        headerTheme: appInfo?.headerTheme || 'light',
        isWhiteLabel,
        lengthCentsCurrency,
        logo,
        name,
        faqUrl: appInfo?.faqUrl || 'https://newtail.freshdesk.com/support/home',
        newTicketUrl:
          appInfo?.newTicketURL ||
          'https://newtail.freshdesk.com/support/tickets/new',
        rechargeEmail: appInfo?.rechargeEmail,
        rechargeMinValue: appInfo?.rechargeMinValue,
        timezone: appInfo?.timezone,
        externalSignupUrl: appInfo?.externalSigupUrl
      }}
    >
      <HelmetProvider>
        <Helmet
          defaultTitle={name}
          titleTemplate={`%s | ${isPending ? 'Carregando...' : name || 'Newtail'}`}
        >
          {appInfo?.favicon && (
            <>
              <link
                data-react-helmet="true"
                rel="icon"
                type="image/png"
                href={appInfo?.favicon}
              />
              <meta
                name="msapplication-TileColor"
                content={appInfo.colors.primary || '#3c3584'}
              />
              <meta
                name="theme-color"
                content={appInfo.colors.primary || '#3c3584'}
              />
            </>
          )}

          {status === 'success' && !data?.favicon && (
            <>
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="57x57"
                href="/images/apple-icon-57x57.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="60x60"
                href="/images/apple-icon-60x60.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="72x72"
                href="/images/apple-icon-72x72.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="76x76"
                href="/images/apple-icon-76x76.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="114x114"
                href="/images/apple-icon-114x114.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="120x120"
                href="/images/apple-icon-120x120.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="144x144"
                href="/images/apple-icon-144x144.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="152x152"
                href="/images/apple-icon-152x152.png"
              />
              <link
                data-react-helmet="true"
                rel="apple-touch-icon"
                sizes="180x180"
                href="/images/apple-icon-180x180.png"
              />
              <link
                data-react-helmet="true"
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/images/android-icon-192x192.png"
              />
              <link
                data-react-helmet="true"
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/images/favicon-32x32.png"
              />
              <link
                data-react-helmet="true"
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/images/favicon-96x96.png"
              />
              <link
                data-react-helmet="true"
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/images/favicon-16x16.png"
              />
              <meta
                name="msapplication-TileImage"
                content="/ms-icon-144x144.png"
              />
              <meta name="msapplication-TileColor" content="#3c3584" />
              <meta name="theme-color" content="#3c3584" />
            </>
          )}
        </Helmet>

        {children}
      </HelmetProvider>
    </AppInfoContext.Provider>
  )
}

function useAppInfo(): AppInfoProviderData {
  const context = useContext(AppInfoContext)

  if (!context) {
    throw new Error('useAppInfo must be used within an AppInfoProvider')
  }

  return context
}

export { AppInfoContext, AppInfoProvider, useAppInfo }
