import { useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { t } from 'i18next'

import { Button } from '~/components/Buttons'

const CreateCampaign: React.FC<DefaultChangeProps> = ({ newValue }) => {
  const [show, setShow] = useState(false)

  return (
    <section>
      <nav>
        <Button
          onClick={() => setShow(o => !o)}
          template="light"
          text={
            show ? `${t('rm:Hide')} ` : `${t('rm:campaign.logs.show_payload')} `
          }
          size="xsmall"
        />
      </nav>

      {show && (
        <article className="payloadCreate">
          <SyntaxHighlighter language="json" style={dracula} showLineNumbers>
            {JSON.stringify(newValue, null, 2)}
          </SyntaxHighlighter>
        </article>
      )}
    </section>
  )
}

export default CreateCampaign
