import { FiGrid } from 'react-icons/fi'

import { t } from 'i18next'

import ProgressBar from '~/components/ProgressBar'
import Status from '~/components/Status'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { getRetailMediaAccountType, isReadOnly } from '~/helpers'

import { accountInfo } from '../../_elements/accountInfo'
import { statusType } from '../../../StatusBar/statusType'
import ToogleActiveCampaign from '../../../ToogleActiveCampaign'
import TitleWithStatus from './elements/TitleWithStatus'

const accountType = getRetailMediaAccountType()

export const schema: CampaignTableSchema = ({ isListAll }) => ({
  /**
   * Informações da campanha
   */
  campaingInfo: {
    title: t('rm:Campaigns'),
    sortKey: 'name',
    render: rowData => (
      <div style={{ display: 'flex', gap: '.5rem' }}>
        {/* Toggle para gerenciar atividade da campanha. Apenas advertiser */}
        {accountType === 'advertiser' && !isReadOnly() && (
          <aside>
            <ToogleActiveCampaign data={rowData} key={rowData.id} isList />
          </aside>
        )}

        <TitleWithStatus
          title={rowData.title}
          linkTo={`/retail-media/campaign/view/${rowData.id}`}
          statusColor={statusType[rowData.status]?.color}
        />
      </div>
    ),
    showTotal: true
  },
  /**
   * Informações da conta vinculada se estiver fora da campanha
   */
  ...(isListAll ? { ...accountInfo } : {}),
  type: {
    title: t('rm:Type'),
    sortKey: 'ad_type',
    render: rowData => (
      <>
        <div style={{ lineHeight: 1, fontSize: '.75rem' }}>
          {rowData.adType}
        </div>
        {rowData?.targetingType && (
          <Badge size="tiny" text={t(`rm:${rowData.targetingType}`)} />
        )}
      </>
    ),
    className: 'order__details col__infos col__title'
  },
  status: {
    title: t('rm:Status'),
    sortKey: 'status',
    render: rowData => (
      <div style={{ lineHeight: 1, fontSize: '.75rem' }}>
        <Status
          status={{
            label: statusType[rowData.status]?.label || rowData.status,
            type: statusType[rowData.status]?.color || 'default'
          }}
        />
      </div>
    ),
    className: 'order__status'
  },
  budget: {
    title: t('rm:dailyBudget'),
    sortInitialToggleDirection: 'DESC',
    sortKey: 'daily_budget',
    render: ({ budget }) =>
      !!budget.total && (
        <>
          <div className="d-inline-flex align-items-center">
            <span>{budget.total}</span>
            {budget.type === 'evenly' && (
              <Tooltip
                icon={<FiGrid size={10} />}
                text={t('rm:settings.budget_type.values.evenly.description')}
              />
            )}
          </div>
          <br />
          <ProgressBar
            goal={budget.raw.dailyBudget}
            value={budget.raw.dailyConsumed}
          />
        </>
      )
  },
  prints: {
    title: t('rm:metrics.impressions'),
    sortKey: 'impressions',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.impressions
  },
  clicks: {
    title: t('rm:metrics.clicks'),
    sortKey: 'clicks',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.clicks
  },
  ctr: {
    title: t('rm:metrics.ctr'),
    helpText: t('rm:metrics.ctr.tooltip'),
    sortKey: 'ctr',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics.ctr
  },
  totalSpent: {
    title:
      accountType === 'publisher'
        ? t('rm:metrics.totalSpent')
        : t('rm:metrics.cost'),
    sortKey: 'total_spent',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.totalSpent
  },
  conversions: {
    title: t('rm:metrics.conversions'),
    sortKey: 'conversions',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.conversions
  },
  conversion_rate: {
    title: t('rm:metrics.conversion_rate'),
    sortKey: 'conversion_rate',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.conversion_rate
  },
  income: {
    title: t('rm:metrics.sales'),
    sortKey: 'income',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.income
  },
  roas: {
    title: t('rm:metrics.roas'),
    helpText: t('rm:metrics.roas.tooltip'),
    sortKey: 'roas',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics?.roas
  },
  adCost: {
    title: t('rm:metrics.adCost'),
    sortKey: 'adcost',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics.adcost
  }
})
