import Panel from '~/components/Panel'
import { Radio } from '~/components/unform'

import AdvertisersSelect from './Advertisers'

const AudienceCreateAdvertiser: React.FC = () => {
  const optionsTypes: RadioOptionProps[] = [
    {
      id: `advertiserCheck_global`,
      value: 'global',
      label: 'Global',
      description: 'Disponibilize a audiência para todos os anunciantes.'
    },
    {
      id: `advertiserCheck_private`,
      value: 'private',
      label: 'Anunciantes selecionados',
      description:
        'Escolha anunciantes específicos que poderão utilizar essa audiência',
      activeChildren: <AdvertisersSelect />
    }
  ]

  return (
    <Panel
      title={'Visibilidade'}
      description={
        'Selecione os anunciantes que poderão utilizar essa audiência.'
      }
    >
      <Radio options={optionsTypes} name="advertiserCheck" required />
    </Panel>
  )
}

export default AudienceCreateAdvertiser
