import { useCallback, useMemo } from 'react'

import SelectSearchable from '~/components/SelectSearchable'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { audienceStatusOptions } from '~/modules/retailMedia/pages/audiences/lib/status'

const queryKey = 'status'

const FilterAudienceStatus: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * <Handle change>
   */
  const queryParams = useQueryParams()
  const selectedValue = queryParams.get(queryKey) as PublisherTagId

  const selectedOption = useMemo(
    () =>
      audienceStatusOptions.find(({ value }) => value === selectedValue) ||
      null,
    [selectedValue]
  )

  const handleChange = useCallback(
    (option: Option) => {
      const value = option?.value

      if (!value) queryParams.delete(queryKey)

      if (value) queryParams.set(queryKey, value)

      history.replace({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <SelectSearchable
      key={selectedOption?.value}
      name="audience-status-select"
      placeholder={t('rm:filterByStatus')}
      options={audienceStatusOptions}
      onChange={handleChange}
      isClearable
      defaultValue={selectedOption}
      isSearchable
      noOptionsMessage={() => t('c:messages.noResultsFound')}
      size="small"
    />
  )
}

export default FilterAudienceStatus
