import { useCallback, useEffect, useMemo, useState } from 'react'
import { HiOutlineUserCircle } from 'react-icons/hi2'
import { IoChatbubblesOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Filters } from '~/components/Filters'
import Loading from '~/components/Loading'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { Radio } from '~/components/unform'
import { listAttendant } from '~/modules/chat/store/actions'
import { CHAT_REDUX_QUERY_ATTENDANT } from '~/modules/chat/store/constants'
import { useAppSelector } from '~/store/hooks'

import * as S from '../styles'

export const InitialStep = ({
  handleChangeStep,
  handleSelectAttendent,
  selectedAttendent,
  textButtonNextStep
}: PropsInitialStep) => {
  const [locations, setLocation] = useState(null)
  const dispatch = useDispatch()

  const {
    chats: { listAttendantData, listAttendantLoading, selectedChat },
    filters: { query }
  } = useAppSelector(state => state)

  const params = useMemo(
    () => ({
      name: query?.queryAttendant,
      location_id: locations?.value
    }),
    [locations, query?.queryAttendant]
  )

  useEffect(() => {
    dispatch(
      listAttendant({
        params
      })
    )
  }, [dispatch, params])

  const handleChangeLocation = location => {
    setLocation(location)
  }

  const listOptions = listAttendantData.map(attendant => ({
    id: attendant.user_id,
    value: attendant.user_id,
    label: attendant.name,
    disabled:
      attendant.user_id === selectedChat?.session?.userId ? true : false,
    sideRight: (
      <Tooltip
        icon={
          <Badge template="primary" iconLeft={<IoChatbubblesOutline />}>
            {attendant.total}
          </Badge>
        }
        text="Número de atendimentos."
        positionProp="left"
      />
    )
  }))

  const handleNextStep = useCallback(
    () => handleChangeStep('confirmation'),
    [handleChangeStep]
  )

  return (
    <>
      <S.TitleResponse>Responsável pelo atendimento:</S.TitleResponse>
      <S.WrapperBadge>
        <Badge template="primary" iconLeft={<HiOutlineUserCircle />}>
          {selectedChat?.session?.userName
            ? selectedChat?.session?.userName
            : 'Sem atendente'}
        </Badge>
      </S.WrapperBadge>
      <S.WrapperFilters>
        <Filters
          query
          liveQuery
          queryKeyState={CHAT_REDUX_QUERY_ATTENDANT}
          queryPlaceholder="Buscar atendente"
          queryAutoComplete="off"
          columns={1}
          locations
          ignoreReduxLocation
          onChangeLocation={handleChangeLocation}
        />
      </S.WrapperFilters>

      <S.WrapperRadioButton>
        <Loading status={listAttendantLoading}>Carregando...</Loading>
        {!listAttendantLoading && (
          <Radio
            options={listOptions}
            name="attendant"
            layout="grid"
            required
            onChange={handleSelectAttendent}
            itemSize="small"
          />
        )}
      </S.WrapperRadioButton>

      <S.WrapperButtonSubmit>
        <Button
          template="primary"
          text={textButtonNextStep}
          onClick={handleNextStep}
          disabled={!selectedAttendent}
        />
      </S.WrapperButtonSubmit>
    </>
  )
}
