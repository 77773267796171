export const adListqueryKeyParams: QueryParamWithDefault<AdListSearchParams>[] =
  [
    { key: 'rmid' },
    { key: 'tag_id' },
    { key: 'campaign_name' },
    { key: 'product_sku' },
    { key: 'ad_status' },
    { key: 'ad_type' },
    { key: 'campaign_targeting' },
    { key: 'order_direction' },
    { key: 'show_inactive' },
    { key: 'order_by' },
    { key: 'quantity', defaultValue: 25 },
    { key: 'page', defaultValue: 1 }
  ]
