import React, { useCallback, useState } from 'react'

import Table from '~/components/Table'
import { InputHidden } from '~/components/unform'
import AdvertisersFilters from '~/modules/retailMedia/components/_tables/AdvertiserFilters'

import AdvertisersTable from './AdvertisersTable'
import { advertisersLinkTableSchema } from './AdvertisersTable/schema'

// import { Container } from './styles';

const AdvertisersSelect: React.FC = () => {
  //
  const [selectedAdvertisers, setSelectedAdvertisers] = useState<
    AdvertiserPublisherLink[]
  >([])

  const handleAdd = useCallback((advertiser: AdvertiserPublisherLink) => {
    setSelectedAdvertisers(prevState => [...prevState, advertiser])
  }, [])

  const handleDelete = useCallback((advertiser: AdvertiserPublisherLink) => {
    setSelectedAdvertisers(prevState => {
      const newState = [...prevState]
      const index = newState.findIndex(
        item => item.advertiser_id === advertiser.advertiser_id
      )

      if (index > -1) {
        newState.splice(index, 1)
      }

      return newState
    })
  }, [])

  return (
    <section className="w-100">
      {selectedAdvertisers?.length > 0 && (
        <article className="mb-3 pb-3 border-bottom w-100">
          <h4>Anunciantes selecionados</h4>

          <Table
            smallerRowPadding
            schema={advertisersLinkTableSchema({
              handleDelete,
              selectedAdvertisers
            })}
            data={selectedAdvertisers}
            total={selectedAdvertisers.length}
            sideBorder
          />

          <InputHidden
            name="advertiser_ids"
            value={selectedAdvertisers.map(item => item.advertiser_id)}
          />
        </article>
      )}

      <article>
        <AdvertisersFilters hideCreateButton />

        <AdvertisersTable
          onAdd={handleAdd}
          onDelete={handleDelete}
          selectedAdvertisers={selectedAdvertisers}
        />
      </article>
    </section>
  )
}

export default AdvertisersSelect
