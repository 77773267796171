import Panel from '~/components/Panel'
import { FormGroup, Input, Label, Textarea } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

const AudienceCreateDetails = () => {
  const { t, tCapitalize } = useAPPTranslation()

  return (
    <Panel title={tCapitalize('c:words.details')}>
      <FormGroup className="mb-3">
        <Label text={tCapitalize('c:words.name')} htmlFor="name" isRequired />
        <Input name="name" required />
      </FormGroup>

      <FormGroup>
        <Label
          text={`${tCapitalize('c:words.description')} (${t('c:words.optional')})`}
          htmlFor="description"
        />
        <Textarea name="description" minHeight="85px" />
      </FormGroup>
    </Panel>
  )
}

export default AudienceCreateDetails
