import {
  createContext,
  useContext,
  useCallback,
  useState,
  PropsWithChildren
} from 'react'

import { v4 as uuidv4 } from 'uuid'

import SidePanelContainer from './components/SidePanelContainer'

export interface SidePanelMessage {
  id: string
  title?: string | null
  content?: React.ReactNode
  type?: string
  size?: 'small' | 'medium' | 'large'
  callbackRemove?: () => void
}

export interface SidePanelContextData {
  addSidePanel(message: Omit<SidePanelMessage, 'id'> & { id?: string }): void
  removeSidePanel({
    callback,
    id
  }?: {
    callback?: () => void
    id?: string
  }): void
}

export const SidePanelContextDefaultValue = {
  addSidePanel: () => null,
  removeSidePanel: () => null
} as SidePanelContextData

export const SidePanelContext = createContext<SidePanelContextData>(
  {} as SidePanelContextData
)

const SidePanelProvider = ({ children }: PropsWithChildren) => {
  const [messages, setMessages] = useState<SidePanelMessage[]>([])

  // id is optional
  const addSidePanel = useCallback(
    ({
      id = null,
      title,
      content,
      type,
      size = 'medium',
      callbackRemove = null
    }: Omit<SidePanelMessage, 'id'> & { id?: string }) => {
      const SidePanel = {
        id: id || uuidv4(),
        title,
        content,
        type,
        size,
        callbackRemove
      }

      document.body.classList.add('sidePanel__open')
      document.body.style.overflow = 'hidden'
      setMessages(state => [...state, SidePanel])
    },
    []
  )

  const removeSidePanel = useCallback(
    ({ callback, id }: { callback?: () => void; id?: string } = {}) => {
      document.body.classList.remove('sidePanel__open')
      document.body.style.overflow = ''
      callback?.()

      setMessages(prevState =>
        id ? prevState.filter(item => item.id !== id) : []
      )
    },
    []
  )

  return (
    <SidePanelContext.Provider value={{ addSidePanel, removeSidePanel }}>
      {children}
      <SidePanelContainer messages={messages} />
    </SidePanelContext.Provider>
  )
}

function useSidePanel(): SidePanelContextData {
  const context = useContext(SidePanelContext)

  if (!context) {
    throw new Error('useSidePanel must be used within an SidePanelProvider')
  }

  return context
}

export { SidePanelProvider, useSidePanel }
