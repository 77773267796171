import { Provider } from 'react-redux'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PersistGate } from 'redux-persist/integration/react'

import 'react-dates/initialize'
import './middlewares/axios'

import IntlProviderConfigured from './_i18n/IntlProviderConfigured'
import Feedback from './components/feedback'
import { AppInfoProvider } from './hooks/useAppInfo'
import { TermsOfServiceProvider } from './hooks/useTermOfService'
import { ThemeProvider } from './hooks/useTheme'
import { UIProvider } from './hooks/useUi'
import Routes from './routes/Routes'
import store, { persistor } from './store'

const queryClient = new QueryClient()

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AppInfoProvider>
      <IntlProviderConfigured>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider>
              <UIProvider>
                <TermsOfServiceProvider>
                  <Routes />
                  <Feedback />
                </TermsOfServiceProvider>
              </UIProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </IntlProviderConfigured>
    </AppInfoProvider>
  </QueryClientProvider>
)

export default App
