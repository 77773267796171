import { FiX } from 'react-icons/fi'
import { useSwipeable } from 'react-swipeable'

import { SidePanelMessage, useSidePanel } from '~/hooks/useSidePanel'

import * as S from './styles'

interface SidePanelProps {
  message: SidePanelMessage
  style: Record<string, unknown>
}

const SidePanel: React.FC<SidePanelProps> = ({ message, style }) => {
  const { removeSidePanel } = useSidePanel()

  const { content, title = null, callbackRemove: callback = null } = message

  const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/i.test(
    navigator.userAgent
  )

  const handlers = useSwipeable({
    trackMouse: true,
    preventScrollOnSwipe: true,
    onSwipedRight: eventData => {
      const { deltaX, deltaY, absX, absY, velocity } = eventData

      // Defina uma lógica personalizada para identificar se o swipe é genuíno ou uma seleção de texto
      const isSwipe = absX > 50 && absY < 20 && velocity > 0.3

      // Somente feche o painel se for considerado um swipe genuíno e mobile
      if (isSwipe && isMobileDevice) {
        removeSidePanel({ callback })
      }
    }
  })

  return (
    <S.AnimatedContainer style={style} {...handlers}>
      <S.Container data-size={message.size}>
        {title && (
          <S.Title>
            <strong>{title}</strong>
          </S.Title>
        )}

        <S.Content>{content}</S.Content>

        <button
          onClick={() => removeSidePanel({ callback })}
          type="button"
          className="position-absolute  top-0 end-0 d-flex  align-items-center justify-content-center bg-danger text-white border-0 fs-2"
          style={{ height: '3rem', width: '3rem', zIndex: 9999 }}
        >
          <FiX />
        </button>
      </S.Container>
    </S.AnimatedContainer>
  )
}

export default SidePanel
