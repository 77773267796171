import { modelPromotionsView } from '~/modules/promotional/models/view';

import * as types from '../constants';

const index = {
  pending: `${types.PROMOTION_VIEW}_PENDING`,
  fulfilled: `${types.PROMOTION_VIEW}_FULFILLED`,
  rejected: `${types.PROMOTION_VIEW}_REJECTED`,
};

export const viewPromotion = {
  [index.pending]: ({ draft }) => {
    draft.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const {
      payload: { selected_offers, trigger = {} },
    } = action;

    const selectedOffers = () => {
      if (selected_offers?.length) {
        return selected_offers;
      }

      if (trigger.total.offers_ids?.length) {
        return trigger.total.offers_ids;
      }

      if (trigger.items.offers_ids?.length) {
        return trigger.items.offers_ids;
      }

      return [];
    };

    draft.loading = false;
    draft.promotion = modelPromotionsView(action.payload);
    draft.selectedProducts = selectedOffers();
  },
  [index.rejected]: ({ draft }) => {
    draft.loading = false;
  },
};
