import { useMemo, createContext, useContext, useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { getAccountId, getUserId, isAdvertiser } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import {
  assignTermsOfServiceService,
  checkTermsOfServiceKey,
  checkTermsOfServiceService,
  pendingTermsOfServiceKeyKey,
  pendingTermsOfServiceKeyService
} from '~/modules/retailMedia/services/configuration/terms-of-service'

import useModal from '../useModal'
import termsOfServiceDataModalDTO from './dto'

const TermsOfServiceContext = createContext<TermsOfServiceContext>(
  {} as TermsOfServiceContext
)

const TermsOfServiceProvider = ({ children }: React.PropsWithChildren) => {
  const [unread, setUnread] = useState(true)
  const [loadingAssign, setLoadingAssign] = useState(false)

  const { isWhiteLabel, name } = useAppInfo()
  const [Modal] = useModal()

  const userId = useMemo(() => getUserId(), [])
  const accountId = useMemo(() => getAccountId(), [])

  /**
   * Check terms of service
   * Se for WL pegamos no /check, se for network pegamos no /pending uma lista
   */

  /**
   * Handle check terms
   */
  const shouldCheckTerms = useMemo(
    () => !!userId && isWhiteLabel && isAdvertiser(),
    [isWhiteLabel, userId]
  )

  const queryKey = [checkTermsOfServiceKey, userId, accountId]

  const { data, isPending } = useQuery({
    enabled: shouldCheckTerms,
    queryKey,
    refetchInterval: 600000,
    queryFn: async () => {
      const { data } = await checkTermsOfServiceService()

      return data
    }
  })

  /**
   * Handle visibility
   */
  const showTerms = useMemo(
    () =>
      data?.status === 'pending' &&
      data?.terms_of_service.url &&
      shouldCheckTerms &&
      !isPending,
    [isPending, shouldCheckTerms, data]
  )

  /**
   * Handle pending on network Newtail
   */
  const shouldCheckTermsNetwork = useMemo(
    () => !!userId && !isWhiteLabel && isAdvertiser(),
    [isWhiteLabel, userId]
  )

  const queryKeyPending = [pendingTermsOfServiceKeyKey, userId, accountId]

  const { data: dataPendencies, isPending: isPendingPendencies } = useQuery({
    enabled: shouldCheckTermsNetwork,
    refetchInterval: 600000,
    queryKey: queryKeyPending,
    queryFn: async () => {
      const { data } = await pendingTermsOfServiceKeyService()

      return data
    }
  })

  /**
   * Handle visibility newtwork
   */
  const showTermsNetwork = useMemo(
    () =>
      !!dataPendencies?.length &&
      dataPendencies?.[0]?.url &&
      shouldCheckTermsNetwork &&
      !isPendingPendencies,
    [dataPendencies, shouldCheckTermsNetwork, isPendingPendencies]
  )

  /**
   * Modal data
   * Se for WL pegamos no /check, se for network pegamos no /pending
   */
  const dataModal = useMemo(() => {
    if (!data && !dataPendencies) return null

    return termsOfServiceDataModalDTO(
      isWhiteLabel
        ? { ...data?.terms_of_service, publisher_name: name }
        : dataPendencies
    )
  }, [data, dataPendencies, isWhiteLabel, name])

  /**
   * Handle assign
   */
  const queryClient = useQueryClient()

  const assignTermsOfService = useMutation({
    mutationFn: assignTermsOfServiceService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey
      })
      queryClient.invalidateQueries({
        queryKey: queryKeyPending
      })

      setLoadingAssign(false)
      setUnread(true)

      toastr.success(
        t('rm:termsOfService.modal.toast.success.title'),
        t('rm:termsOfService.modal.toast.success.message')
      )
    },
    onError: () => {
      setLoadingAssign(false)
      toastr.error(
        t('rm:termsOfService.modal.toast.error.title'),
        t('rm:termsOfService.modal.toast.error.message')
      )
    }
  })

  const handleAssign = async () => {
    await assignTermsOfService.mutateAsync({
      version: dataModal?.version,
      publisher_id: dataModal?.publisher_id
    })
  }

  return (
    <TermsOfServiceContext.Provider value={{ data: dataModal }}>
      {(showTerms || showTermsNetwork) && (
        <Modal
          bluredBg
          isOpen={showTerms || showTermsNetwork}
          title={
            !isWhiteLabel && dataPendencies.length > 1
              ? `${t('rm:termsOfService.modal.warnings')} [ 1 / ${dataPendencies.length} ]`
              : null
          }
        >
          <header className="text-center">
            <h5>
              {t('rm:termsOfService.modal.titleWarningOf')}
              {dataModal.name}
            </h5>
            <h1>{t('rm:termsOfService.modal.title')}</h1>
          </header>

          <section className="bg-light rounded p-3 border border-gray">
            <p>{t('rm:termsOfService.modal.body.one')}</p>
            <p>{t('rm:termsOfService.modal.body.two')}</p>
            <p className="mb-0">
              {t('rm:termsOfService.modal.body.three')}{' '}
              <a
                href={dataModal?.url}
                target="_blank"
                rel="noreferrer"
                onClick={() => setUnread(false)}
              >
                {t('rm:termsOfService.modal.termsOfService')}
              </a>
              .
            </p>
          </section>

          <footer className="d-flex flex-column gap-4 mt-4">
            {unread && (
              <Button
                text={t('rm:termsOfService.modal.readButton')}
                as="a"
                href={dataModal?.url}
                target="_blank"
                rel="noreferrer"
                onClick={() => setUnread(false)}
                iconRight={<FiExternalLink />}
              />
            )}

            {!unread && (
              <Button
                text={t('rm:termsOfService.modal.readAndAcceptedButton')}
                textLoading={t('rm:termsOfService.modal.saving')}
                onClick={handleAssign}
                loading={loadingAssign}
                template="success"
              />
            )}

            {/* <Button template="transparent" text="Voltar" /> */}
          </footer>
        </Modal>
      )}

      {children}
    </TermsOfServiceContext.Provider>
  )
}

function useTermsOfSerivce(): TermsOfServiceContext {
  const context = useContext(TermsOfServiceContext)

  if (!context) {
    throw new Error(
      'useTermsOfSerivce must be used within an TermsOfServiceProvider'
    )
  }

  return context
}

export { TermsOfServiceContext, TermsOfServiceProvider, useTermsOfSerivce }
