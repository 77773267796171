import AlertInfo from '~/components/AlertInfo'
import Panel from '~/components/Panel'
import { InputMoney, Radio } from '~/components/unform'
import { formatMoneyV2, isAdvertiser, isPublisher } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

const AudienceFieldsetPrice: React.FC = () => {
  const { t } = useAPPTranslation()

  const optionsTypes: RadioOptionProps[] = [
    {
      id: `priceCheck_free`,
      value: 'free',
      label: t('rm:audience.create.price.free'),
      description: t('rm:audience.create.price.free.description')
    },
    {
      id: `priceCheck_fixed`,
      value: 'fixed',
      label: t('rm:audience.create.price.fixed'),
      description: t('rm:audience.create.price.fixed.description'),
      activeChildren: (
        <InputMoney
          name="price"
          required
          placeholder={formatMoneyV2({ value: 0 })}
        />
      )
    }
  ]

  return (
    <Panel
      title={'Custo da audiência'}
      description={
        'Valor adicional a ser cobrado nas campanhas pelo uso da audiência.'
      }
    >
      {isAdvertiser() && (
        <AlertInfo
          template="warning"
          text={t('rm:audience.create.price.advertiserAlertBefore')}
        />
      )}

      {isPublisher() && (
        <Radio options={optionsTypes} name="priceCheck" required />
      )}
    </Panel>
  )
}

export default AudienceFieldsetPrice
