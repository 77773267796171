import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import { NoteModal } from '~/components/NoteModal'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useModal from '~/hooks/useModal'
import i from '~/i18n'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { ModalConfirmations } from '~/modules/retailMedia/components/StatusBar/_components/Modal'
import {
  approveAudienceService,
  rejectAudienceService
} from '~/modules/retailMedia/services/audience/actions'

import AudienceFieldsetPrice from '../../create/elements/FieldsetPrice'

type Props = {
  audienceId: AudienceId
}

const SectionAudienceReview: React.FC<Props> = ({ audienceId }) => {
  const { t, tToasts } = useAPPTranslation()
  const { currencyUseCents } = useAppInfo()

  const queryClient = useQueryClient()

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  /**
   * <handle apporve>
   */
  const { mutate: handleApprove, isPending } = useMutation({
    mutationKey: ['audienceApprove'],
    mutationFn: approveAudienceService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['audience', 'view', audienceId]
      })

      tToasts({
        prefix: 'rm:audience.approve.toast.success',
        toastFunction: toastr.success
      })
    },
    onError: error => {
      tToasts({
        prefix: 'c:messages.errorOccurred',
        toastFunction: toastr.error
      })

      setError(error)
    }
  })

  const handleSubmit = (
    formData: Pick<AudienceCreateFormData, 'priceCheck' | 'price'>
  ) => {
    let price = 0
    if (formData.priceCheck === 'fixed') {
      const priceRaw = formData?.price?.numAsString

      if (!priceRaw) {
        console.error('Price is required')

        tToasts({
          prefix: 'c:messages.errorOccurred',
          toastFunction: toastr.error
        })

        return
      }

      price = currencyUseCents ? Number(priceRaw) / 100 : Number(priceRaw)
    }

    handleApprove({ id: audienceId, body: { price, billing_type: 'up_front' } })
  }
  /*** </handle apporve */

  /**
   * <handle reject>
   */
  const [contentModal, setContentModal] = useState<ContentNoteModal | null>(
    null
  )
  const [Modal, showConfirmationModal, toggleConfirmationModal] = useModal()

  const { mutate: handleRejectAudience } = useMutation({
    mutationKey: ['audienceReject'],
    mutationFn: rejectAudienceService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['audience', 'view', audienceId]
      })

      tToasts({
        prefix: 'rm:audience.reject.toast.success',
        toastFunction: toastr.success
      })
    },
    onError: error => {
      tToasts({
        prefix: 'c:messages.errorOccurred',
        toastFunction: toastr.error
      })

      setError(error)
    }
  })

  const handleReject = () => {
    setContentModal({
      titleModal: t('rm:audience.reject.modal.title'),
      labelMessage: t('rm:audience.reject.modal.subtitle'),
      subtitletLabelMessage: t('rm:audience.reject.modal.subtitle.description'),
      textButton: t('c:actions.reject'),
      templateButton: 'danger',
      noteIsRequired: true,
      hasCancelButton: true
    })

    toggleConfirmationModal()
  }

  const handleSubmitReject = ({ note }: { note: string }) => {
    handleRejectAudience({ id: audienceId, body: { note } })
  }
  /*** </handle reject> */

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <AudienceFieldsetPrice />

        <AlertOnError />

        <div className="form-default__buttons">
          <Button
            text={t('c:actions.reject')}
            size="small"
            template="deleteOutline"
            loading={isPending}
            disabled={isPending}
            onClick={handleReject}
          />

          <Button
            type="submit"
            text={t('c:actions.approve')}
            size="small"
            template="success"
            loading={isPending}
            disabled={isPending}
          />
        </div>
      </Form>

      <NoteModal
        Modal={Modal}
        showConfirmationModal={showConfirmationModal}
        handleSubmit={handleSubmitReject}
        toggleConfirmationModal={toggleConfirmationModal}
        contentModal={contentModal}
      />
    </>
  )
}

export default SectionAudienceReview
