import { bffRetailMedia } from '~/middlewares/axios'

const baseURL = '/audience/actions'

export const approveAudienceService = ({
  id,
  body
}: ApproveAudienceServiceProps) =>
  bffRetailMedia.patch(`${baseURL}/approve/${id}`, body)

export const rejectAudienceService = ({
  id,
  body
}: RejectAudienceServiceProps) =>
  bffRetailMedia.patch(`${baseURL}/reject/${id}`, body)
