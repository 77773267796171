import * as types from '../constants'

const index = {
  addProduct: types.ADD_PRODUCT,
  deleteProduct: types.DELETE_PRODUCT,
  deleteAllProducts: types.DELETE_ALL_PRODUCTS,
  addProductsBulkPending: `${types.ADD_PRODUCTS_BULK}_PENDING`,
  addProductsBulkFulfilled: `${types.ADD_PRODUCTS_BULK}_FULFILLED`,
  addProductsBulkRejected: `${types.ADD_PRODUCTS_BULK}_REJECTED`
}

export const handleProducts = {
  [index.addProduct]: ({ draft, action }) => {
    const product = action.payload

    const oldSelectedProducts = draft.selectedProducts

    draft.selectedProducts = [...oldSelectedProducts, product]
  },
  [index.deleteProduct]: ({ draft, action }) => {
    const oldSelectedProducts = draft.selectedProducts
    const removeId = action.payload.id

    const filteredProducts = oldSelectedProducts.filter(
      item => item.id !== removeId
    )

    draft.selectedProducts = filteredProducts
  },
  [index.deleteAllProducts]: ({ draft }) => {
    draft.selectedProducts = []
  },
  [index.addProductsBulkPending]: ({ draft }) => {
    draft.loadingAddBulk = true
  },
  [index.addProductsBulkFulfilled]: ({ draft, action }) => {
    const oldSelectedProducts = draft.selectedProducts

    const findIndexOffer = offer =>
      oldSelectedProducts.findIndex(
        item => offer.external_id === item.external_id
      )

    const selectedOffers = action.payload.filter(
      item => findIndexOffer(item) === -1
    )

    draft.selectedProducts = [...oldSelectedProducts, ...selectedOffers]

    draft.loadingAddBulk = false
  },
  [index.addProductsBulkRejected]: ({ draft }) => {
    draft.loadingAddBulk = false
  }
}
