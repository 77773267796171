import * as types from '../constants';

const index = {
  pending: `${types.PROMOTIONS_CREATE}_PENDING`,
  fulfilled: `${types.PROMOTIONS_CREATE}_FULFILLED`,
  rejected: `${types.PROMOTIONS_CREATE}_REJECTED`,
  clear: `${types.CLEAR_PROMOTION_STORE}`,
};

export const createPromotion = {
  [index.pending]: ({ draft }) => {
    draft.loadingCreate = true;
  },
  [index.fulfilled]: ({ draft }) => {
    draft.loadingCreate = false;
    draft.selectedProducts = [];
  },
  [index.rejected]: ({ draft }) => {
    draft.loadingCreate = false;
  },
  [index.clear]: ({ draft }) => {
    draft.promotion = null;
    draft.selectedProducts = [];
  },
};
